import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { BehaviorSubject, combineLatest } from "rxjs";

export const appTheme = {
  main: { source: "main-theme" },
} as const;

type Theme = (typeof appTheme)[keyof typeof appTheme];
type ThemeName = Theme["source"];

type ThemesState = {
  current?: Theme;
  previous?: Theme;
  styleElementRef?: HTMLLinkElement;
};

@Injectable({ providedIn: "root" })
export class ThemeService {
  themesState = new BehaviorSubject<ThemesState>({});
  darkMode = new BehaviorSubject<boolean>(false);

  constructor(private localStorageService: LocalStorageService) {
    this.watchThemeChanges();
    this.darkMode.next(this.localStorageService.getDarkMode());
  }

  enableDarkMode() {
    this.setDarkMode(true);
  }

  disableDarkMode() {
    this.setDarkMode(false);
  }

  setTheme(theme: Theme) {
    const currentState = this.themesState.value;

    if (theme === currentState.current) {
      return;
    }

    const nextState: ThemesState = {
      previous: currentState.current,
      current: theme,
      styleElementRef: this.insertThemeStyleToDom({
        theme: theme.source,
        ref: currentState.styleElementRef,
      }),
    };

    this.themesState.next(nextState);
  }

  private setDarkMode(darkMode: boolean) {
    this.darkMode.next(darkMode);
    this.localStorageService.setDarkMode(darkMode);

    const bgColor = this.getBgColor();
    this.localStorageService.setKeyValue("bgColor", bgColor);
    document.body.style.backgroundColor = bgColor;
  }

  private insertThemeStyleToDom = (options: {
    theme: ThemeName;
    ref?: HTMLLinkElement;
  }): HTMLLinkElement => {
    if (options.ref !== undefined) {
      document.head.removeChild(options.ref);
    }

    const href = `${options.theme}.css`;

    const style = document.createElement("link");
    style.href = href;
    style.type = "text/css";
    style.rel = "stylesheet";

    document.head.appendChild(style);

    return style;
  };

  private watchThemeChanges() {
    combineLatest([this.darkMode, this.themesState]).subscribe(([darkMode]) => {
      if (darkMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    });
  }

  private getBgColor(): string {
    return getComputedStyle(document.body).getPropertyValue(
      "--mat-app-background-color",
    );
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, skip } from "rxjs";

type LocalStorageState = {
  darkMode: boolean;
  bgColor: string;
};

const LOCAL_STORAGE_STATE_KEY = "STATE";

const initialLocalStorageState: LocalStorageState = {
  darkMode: false,
  bgColor: "#ffffff",
};

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private storage$: BehaviorSubject<LocalStorageState>;

  constructor() {
    this.initializeLocalStorageState();

    this.storage$ = new BehaviorSubject(this.getState());

    this.storage$.pipe(skip(1)).subscribe((state) => {
      this.setState(state);
    });
  }

  getDarkMode(): boolean {
    const state = this.getState();
    return state.darkMode;
  }

  setDarkMode(darkMode: boolean) {
    const currentState = this.getState();

    this.storage$.next({ ...currentState, darkMode });
  }

  getKeyValue<K extends keyof LocalStorageState>(key: K): LocalStorageState[K] {
    return this.storage$.value[key];
  }

  setKeyValue<K extends keyof LocalStorageState>(
    key: K,
    value: LocalStorageState[K],
  ) {
    const currentState = this.getState();
    this.storage$.next({ ...currentState, [key]: value });
  }

  private initializeLocalStorageState() {
    const state = localStorage.getItem(LOCAL_STORAGE_STATE_KEY);

    if (state === null) {
      this.setState(initialLocalStorageState);
    } else {
      const stateObject = this.getState();
      this.setState({ ...initialLocalStorageState, ...stateObject });
    }
  }

  private getState(): LocalStorageState {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_KEY)!);
  }

  private setState(value: any) {
    localStorage.setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(value));
  }
}

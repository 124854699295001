import { inject, Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private readonly spinnerService = inject(NgxSpinnerService);

  loadingCount = 0; // Tracks the number of ongoing requests
  private loading$ = new BehaviorSubject<boolean>(false); // Observable for loading state

  constructor() {
    this.loading$.subscribe((loading) => {
      if (loading) {
        this.spinnerService.show(undefined, {
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });
      } else {
        this.spinnerService.hide();
      }
    });
  }

  /**
   * Returns the current loading state.
   */
  isLoading(): boolean {
    return this.loading$.value;
  }

  /**
   * Increments the loading counter and updates the loading state if necessary.
   */
  startLoading(): void {
    this.loadingCount++;

    if (!this.loading$.value) {
      this.loading$.next(true);
    }
  }

  /**
   * Decrements the loading counter and updates the loading state if necessary.
   * Ensures that `loadingCount` never goes below zero.
   */
  stopLoading(): void {
    this.loadingCount = Math.max(0, this.loadingCount - 1);

    if (this.loadingCount === 0 && this.loading$.value) {
      this.loading$.next(false);
    }
  }

  /**
   * Returns the current loading state (synchronous).
   */
  getState(): boolean {
    return this.loading$.value;
  }

  /**
   * Manually sets the loading state.
   * Use cautiously; primarily intended for edge cases.
   */
  setState(state: boolean): void {
    this.loading$.next(state);
  }
}

import { inject, Injectable } from '@angular/core';
import {
  Auth,
  authState,
  idToken,
  signInWithEmailAndPassword,
  UserCredential,
  signOut,
} from '@angular/fire/auth';
import { untilDestroyed } from '@libs/utils';

import { Observable, scheduled, asyncScheduler } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _auth = inject(Auth);
  private readonly takeUntilDestroyed = untilDestroyed();

  authState$ = authState(this._auth);
  idToken$ = idToken(this._auth);

  //   byGoogle(): Promise<UserCredential> {
  //     return signInWithPopup(this._auth, new GoogleAuthProvider()).then(
  //       (auth) => this._setUserData(auth)
  //     );
  //   }

  //   signup(email: string, password: string): Promise<UserCredential> {
  //     return createUserWithEmailAndPassword(
  //       this._auth,
  //       email.trim(),
  //       password.trim()
  //     ).then((auth) => this._setUserData(auth));
  //   }

  login(email: string, password: string): Observable<UserCredential> {
    return scheduled(
      signInWithEmailAndPassword(this._auth, email.trim(), password.trim()),
      asyncScheduler
    ).pipe(this.takeUntilDestroyed());
  }

  logout(): Observable<void> {
    return scheduled(signOut(this._auth), asyncScheduler);
  }
}
